.container {
  display: block;
  height: 100%;
  color: white;
  overflow-y: hidden;
}
.containerBigScreen {
  display: none;
}
.containerCarousel {
  display: block;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.carousel {
  height: 100%;
}
.target_container {
  box-sizing: border-box;
  display: grid;
  height: 100%;
  grid-template-columns: 2em 1fr 20px 3fr 2em;
  grid-template-rows: 1em 1fr 3fr 1em;
  grid-template-areas:
    ". . . . ."
    ". title title title ."
    ". menuBox menuBox menuBox ."
    ". . . . .";
}
.icon {
  display: none !important;
}
.target_short_name {
  grid-area: title;
  align-self: center; 
  justify-self: start;
  font-size: 0.8em;
  margin: 0;
}

.target_short_name_bigScreen{
  padding: 0.65em 1rem;
  max-width: 75%;
  min-width: 75%;
  margin: 0.1em 0 1em 0;
  font-size: 0.8em;
  text-align: center;
  line-height: 1.2;
  letter-spacing: 0.5px;
}

.text_box {
  grid-area: menuBox;
  align-self: center;
  font-weight: bold;
}

.message_container {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 1em;
  border-radius: 10px;
  position: relative;
  top: 50%;
  left: 50%;
  max-width:250px;
  transform: translate(-50%, -50%);
}
.error {
  background-color: #ffbaba;
  border: 1px solid red;
}
.loading {
  background-color: rgb(176, 215, 252);
  border: 1px solid rgb(2, 78, 145);
}
.loading_text,
.error_text{
  text-align: center;
}
.loading_text {
  color: rgb(2, 78, 145);
  font-size: 2em;
}
.error_text {
  font-size: 2em;
  color: red;
}

.carousel.control-arrow:before,.carousel.carousel-slider.control-arrow:before {
  content: none;
}
.control-arrow.control-next{
  background: red;
}
@media (min-width: 1261px) {
  .containerCarousel {
    display: none;
  }
  .containerBigScreen {
    display: block;
  }
  .container {
    background-color: white;
    overflow-y: scroll;
  }
  .target_container {
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
  }
  .target_container:last-child {
    padding-bottom: 1em;
  }
  .icon_container {
    width: 75%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    margin-top: 1em;
    padding:1em;
    margin:0;
  }
  .icon {
    display: block !important;
    width: 50%;
    margin: auto;
  }
}

@media (min-width: 500px) and (max-width: 1260px) {
  .target_container {
    display: grid;
    height: 100%;
    grid-template-columns: 3em 1fr 20px 3fr 3em;
    grid-template-rows: 0.75em 1fr 3fr 0.75em;
    grid-template-areas:
      ". . . . ."
      ". title title title ."
      ". logo . menuBox ."
      ". . . . .";
  }
  .icon {
    display: block !important;
    grid-area: logo;
    max-width: 80px;
    justify-self: left;
    align-self: center;
  }
}
@media (min-width: 600px) and (max-width: 1260px) {
  .target_short_name {
    font-size: 1em;
  }
}
