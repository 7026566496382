body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  overflow-y: auto;
  font-family: "Source Sans Pro", sans-serif;
}

.main {
  width: 100vw;
  height: 100%;
  margin-top: 65px;
}

.content {
  padding: 1em 2em 2em 2em;
  width: 80%;
  max-width: 1000px;
  margin: auto;
}

.downloadBtn {
  display: block;
  margin: 4em auto 0;
  font-size: 1.1em;
  text-align: center;
  color: white;
  background-color: rgb(2, 78, 145);
  max-width: 250px;
  padding: 1em 2em;
  box-sizing: border-box;
  border-radius: 5px;
  border: none;
  align-self: center;
  text-decoration: none;

}

.downloadBtn:hover,
.downloadBtn:focus {
  background: rgba(0, 157, 219, 255);
  cursor: pointer;
  transform: scale(1.1);
  transition: all 250ms;
}

.downloadContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.downloadIcon {
  margin-right: 1em;
}

.intro {
  margin-bottom: 2em;
  font-size: 0.8em;
}

.introTitle {
  text-align: center;
}

.additionalInfoTitle {
  text-align: center;
  font-size: 0.8em;
}

.additionalInfoText {
  font-size: 0.8em;
}

.additionalInfoTextContainer {
  margin-bottom: 2em;
}

.arrowsLegendIcon {
  display: block;
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.goalIcon {
  display: none;
}

.goalTitle {
  font-size: 0.8em;
  font-weight: bold;
  font-style: italic;
}

.goalText {
  font-size: 0.8em;
  font-style: italic;
}

.goalTitle:hover {
  cursor: pointer;
}

.goalsContainer {
  display: flex;
  margin-bottom: 1em;
}

.goalBox {
  display: flex;
  align-items: center;
}

@media(min-width:500px) {
  .goalIcon {
    display: block;
    align-self: flex-start;
  }

  .intro,
  .goalTitle,
  .goalText,
  .additionalInfoTitle,
  .additionalInfoText {
    font-size: 1em;
  }

  .additionalInfoTextContainer {
    margin-bottom: 4em;
  }

  .goalTitle {
    margin-left: 3em;
  }

  .goalContent {
    padding: 0 2em;
  }

}