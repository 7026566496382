.titleContainer {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  color: white;
}
.indicatorName,
.targetName {
  display: none;
  text-align: center;
  margin: 0;
  margin-bottom: 1em;
  font-size: 0.95em;
}
.targetName{
  padding:1em 1em 0 1em;
  font-weight: 400;
  margin-bottom: 0;
}
.indicatorName{
  margin-bottom:0;
  padding:.5em 1em 1em 1em;
}

@media (min-width: 1261px) {
  .indicatorName,
  .targetName {
    display: block;
  }
}

