h1 {
  margin: 0;
}
.container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 57px);
}
.responsive_container{
  width:100% !important;
  height: 90% !important;
}
.lineChart{
  position:absolute !important;
}
 .yAxisTitle{
   text-anchor: middle !important;
} 

@media (min-width:500px){
  .container{
    flex-direction: row-reverse;
    align-items: flex-start;

  }
  .responsive_container{
    width:90% !important;
    height: 100% !important;

  }
}
@media (min-width: 1261px){
  .container {
    height: calc(100% - 57px - 77px);
  }
}

