body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  overflow-y: auto;
  font-family: "Source Sans Pro", sans-serif;
}

.main{
  width: 100vw;
  height: 100%;  
  margin-top:65px;
}
.content{
padding:1rem 2rem 2rem 2rem;
width:80%;
max-width: 1000px;
margin:auto;
}
.introWrapper{
  position: relative;
  background-color: white;
  height: 35vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 60% 70%;
}
.intro{
  margin-bottom:2em;
  font-size:0.8em;
}
.introTitleBox{
  position:absolute;
  bottom:2vh;
  left:0;
  width: 100%;
  height: 6vh;
  content: '';
  background-color: white;
}
.introTitle{
  position: relative;
  top: 28vh;
  left: 0;
  width: 80%;
  max-width: 1000px;
  padding:0 2rem;
  margin: auto;
  font-size: 3vh;
  text-align: center;
  
}
.buttonsContainer{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.btnImage{
  width:100%;
  max-width: 300px;
  margin:1em;
}
.btnImage:hover,
.btnImage:focus{
  cursor: pointer;
  filter: drop-shadow(8px 8px 10px gray);
} 
.btnImage_active{
  filter: drop-shadow(8px 8px 10px gray);
}
.trendTitle{
  font-size: 1.3em;
  text-align: center;
  margin-bottom: 1.5em;
}
.trendSubtitle{
  position:relative;
  font-size:1em;
}

.trendSubtitle::after{
  content:"";
  display: block;
  position:absolute;
  left:0;
  bottom:0;
  height:0.5px;
  background:black;
  width: 100%;
}

.trendText{
  margin: 1em 0 2em 0;
  font-size:0.8em;
}
.trendIndicatorsSubtitle{
  margin-bottom: 2rem;
}

.additionalInfo{
  font-size: 0.7em;
  font-style: italic;
}
.goalsImageWrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom:3em;
}
.goalImage{
  width:100%;
  max-width: 80px;
}
.trendImagesWrapper{
  display:flex;
  flex-direction: column;
  align-items: center;
  margin-bottom:2em;
}
.trendImage{
  width:100%;
  max-width: 600px;
  pointer-events: none;
}
.arrowsImage{
  width:80%;
  max-width:500px;
  margin: auto;
    margin-top: 2em;
    display: block;
}


@media(min-width:700px){
  .goalIcon{
    display: block;
    align-self: flex-start;
  }
  .intro,
  .goalTitle,
  .goalText,
  .additionalInfoTitle,
  .additionalInfoText{
    font-size:1em;
  }
  .additionalInfoTextContainer{
    margin-bottom:4em;
  }
  .goalTitle{
    margin-left:3em;
  }
  .goalContent{
    padding:0 2em;
  }
  .buttonsContainer{
    flex-direction: row;
  }
  .btnImage{
    width:30%;
  }

.trendTitle{
  font-size:1.8em;
}
.trendSubtitle{
  font-size:1.2em;
}

.trendText{
  font-size:1em;
}

.additionalInfo{
  font-size: 0.8em;
}

.introTitleBox{
  width: 65%;
  max-width: 900px;
  bottom:3vh;
  height: 8vh;
}
.introTitle{
  top:25vh;
  font-size: 5vh;
  text-align: left;
}

}
@media(min-width:801px){
.trendImagesWrapper{
  flex-direction: column;
  justify-content:space-around;
}
  .goalImage{
    max-width: 120px;
  }
}
