.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 65px);
  width: 100vw;
}

.textContainer {
  max-width: 300px;
  padding: 0 1em 1em 1em;
  display: flex;
  flex-direction: column;
}

.introText,
.downloadText {
  text-align: left;
  font-size: 0.8em;
}

.downloadText {
  color: black;
  text-decoration: none;
  font-size: inherit;
  border-bottom: 1px solid rgb(170, 170, 170);
}

.downloadTextWrapper {
  margin-bottom: 3em;
}

.downloadText:hover,
.downloadText:focus {
  /* background-color: rgb(223, 223, 223); */
  border-bottom: 1px solid black;
}

.logoIcon {
  text-align: center;
  width: 100%;
  max-width: 200px;

}

.goalIcon {
  max-width: 75%;
  max-height: calc(100vh - 65px - 63.2px);
  transform: scale(0.95);
  transition: transform 250ms;
}

.goalIcon:hover {
  cursor: pointer;
  transform: scale(1);
}

.exploreBtn {
  font-size: 1.25em;
  text-align: center;
  color: white;
  background-color: rgb(2, 78, 145);
  max-width: 200px;
  padding: 1em 2em;
  box-sizing: border-box;
  border-radius: 5px;
  border: none;
  align-self: center;
}

.exploreBtn:hover,
.exploreBtn:focus {
  background: rgba(0, 157, 219, 255);
  cursor: pointer;
  transform: scale(1.1);
  transition: all 250ms;
}

@media(min-width:500px) {
  .textContainer {
    max-width: 450px;
    flex-direction: row;
    margin-bottom: 3em;
  }

  .introText,
  .downloadText {
    margin-right: 2em;
    margin-bottom: 1em;
  }
}

@media(min-width:801px) {
  .wrapper {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 65px - 52.4px);
  }

  .textContainer {
    flex-direction: column;
    margin-bottom: 0;
  }

  .goalIcon {
    max-height: calc(100vh - 65px - 52.4px);
    max-width: 70%;
  }

  .introText,
  .downloadText {
    font-size: 1em;
    margin: 2em 0 0 0;
  }

  .exploreBtn {
    align-self: flex-start;
  }
}